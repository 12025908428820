import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import avatar1 from "../../assets/images/users/avatar-1.jpg";

const WelcomeComp = () => {
  const { user, balance } = useSelector((state) => ({
    user: state.Login.user,
    balance: state.invoices.balance,
  }));

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back!</h5>
                <h5 className="font-size-15 text-truncate mt-3">
                  {user?.first_name} {user?.last_name}
                </h5>
                <p className="text-muted mb-0 text-truncate">
                  Role: {user?.is_admin === 2 && "Owner"}{" "}
                  {user?.is_admin === 1 && "Admin"}{" "}
                  {user?.is_admin === 0 && "Sales Rep"}
                </p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={user?.image || avatar1} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col>
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">
                      {balance.balance} {balance.currency}
                    </h5>
                    <p className="text-muted mb-0">Twilio Balance</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="mt-4">
            <Link to="/profile" className="btn btn-primary  btn-sm">
              View Profile <i className="mdi mdi-arrow-right ms-1"></i>
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
