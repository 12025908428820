import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  GET_CUSTOMERS_CONTACT,
  GET_CUSTOMERS_CONTACT_SUCCESS,
  ADD_NEW_CUSTOMERS_CONTACT,
  ADD_NEW_CUSTOMERS_CONTACT_SUCCESS,
  GET_CUSTOMERS_CONTACT_PROFILE,
  GET_CUSTOMERS_CONTACT_PROFILE_SUCCESS,
  UPDATE_CUSTOMERS_CONTACT,
  UPDATE_CUSTOMERS_CONTACT_SUCCESS,
  DELETE_CUSTOMERS_CONTACT,
  DELETE_CUSTOMERS_CONTACT_SUCCESS,
  CLEAR_ON_LOGOUT,
  API_ERROR,
} from "./actionTypes";
//=================================
export const getUsers = (offset) => ({
  type: GET_USERS,
  payload: offset,
});
export const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});
//================================
export const addNewUser = (user) => ({
  type: ADD_NEW_USER,
  payload: user,
});
export const addUserSuccess = (user) => ({
  type: ADD_USER_SUCCESS,
  payload: user,
});
//=================================
export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
});
export const getUserProfileSuccess = (userProfile) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
});
//===================================
export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});
export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});
//========================================
export const deleteUser = (user) => ({
  type: DELETE_USER,
  payload: user,
});
export const deleteUserSuccess = (user) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
});

//===================================================
//===================================================
//===================================================

export const getCustomersContact = (offset) => ({
  type: GET_CUSTOMERS_CONTACT,
  payload: offset,
});
export const getCustomersContactSuccess = (users) => ({
  type: GET_CUSTOMERS_CONTACT_SUCCESS,
  payload: users,
});
//================================
export const addNewCustomerContact = (user) => ({
  type: ADD_NEW_CUSTOMERS_CONTACT,
  payload: user,
});
export const addNewCustomerContactSuccess = (user) => ({
  type: ADD_NEW_CUSTOMERS_CONTACT_SUCCESS,
  payload: user,
});
//=================================
export const getCustomerContactProfile = (user) => ({
  type: GET_CUSTOMERS_CONTACT_PROFILE,
  payload: user,
});
export const getCustomerContactProfileSuccess = (userProfile) => ({
  type: GET_CUSTOMERS_CONTACT_PROFILE_SUCCESS,
  payload: userProfile,
});
//===================================
export const updateCustomerContact = (id, newData) => ({
  type: UPDATE_CUSTOMERS_CONTACT,
  payload: { id, newData },
});
export const updateCustomerContactSuccess = (user) => ({
  type: UPDATE_CUSTOMERS_CONTACT_SUCCESS,
  payload: user,
});
//========================================
export const deleteCustomerContact = (id) => ({
  type: DELETE_CUSTOMERS_CONTACT,
  payload: id,
});
export const deleteCustomerContactSuccess = (id) => ({
  type: DELETE_CUSTOMERS_CONTACT_SUCCESS,
  payload: id,
});
export const clearContactsOnLogout = () => ({
  type: CLEAR_ON_LOGOUT,
  payload: {},
});

export const apiErrorContacts = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
