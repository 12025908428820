import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Input,
  Button,
} from "reactstrap";
import { isEmpty, map } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";

import Breadcrumb from "../../components/Common/Breadcrumb";

import { getBalance } from "store/actions";

const customersTestData = [
  {
    name: "Customer1",
    company: "qwe",
    total: "$200",
    id: 1,
    isOpenThee: false,
    subAccounts: [
      {
        name: "subaccount1",
        phone: "(000) 000-0000",
        listThing: [
          { item: "sms", price: "$5.00" },
          { item: "mms", price: "$3.34" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
      {
        name: "subaccount2",
        phone: "(000) 000-1111",
        listThing: [
          { item: "sms", price: "$3.65" },
          { item: "mms", price: "$10.01" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
    ],
    accountTotal: "$32.00",
  },
  {
    name: "Customer2",
    company: "oioi",
    total: "$300",
    id: 2,
    isOpenThee: false,
    subAccounts: [
      {
        name: "subaccount1",
        phone: "(000) 000-3333",
        listThing: [
          { item: "sms", price: "$5.00" },
          { item: "mms", price: "$3.34" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
      {
        name: "subaccount2",
        phone: "(000) 000-2222",
        listThing: [
          { item: "sms", price: "$3.65" },
          { item: "mms", price: "$10.01" },
          { item: "Member Fee", price: "$5.00" },
        ],
        total: "$13.34",
      },
    ],
    accountTotal: "$32.00",
  },
];

const BillingSuperAdmin = (props) => {
  const dispatch = useDispatch();

  const { balance } = useSelector((state) => ({
    balance: state.invoices.balance,
  }));

  useEffect(() => {
    dispatch(getBalance());
  }, [dispatch]);

  const [customersTest, setCustomersTest] = useState(customersTestData);

  const [state, setState] = useState({ loading: false });

  const params = props.router.params;

  const searchUsers = () => {};

  const handleOpenTreeToggle = (id) => {
    setCustomersTest((pS) => {
      const updatedList = pS.map((customer) => {
        if (customer.id === id) {
          customer.isOpenThee = !customer.isOpenThee;
        }
        return customer;
      });

      return updatedList;
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb
            title="Billing Admin"
            breadcrumbItems={props?.router?.location?.pathname}
          />

          <div className="search-box chat-search-box py-2 d-flex flex-row justify-content-between">
            <div className="position-relative">
              <Input
                onKeyUp={searchUsers}
                id="search-user"
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
          <ul
            className="list-unstyled chat-list bg-white rounded-3 p-3"
            id="recent-list"
          >
            <PerfectScrollbar style={{ height: "calc(100vh - 280px)" }}>
              {map(customersTest, (customer) => (
                <li key={customer.id}>
                  <div className="d-flex align-items-center">
                    <div
                      className="align-self-center me-3"
                      onClick={() => handleOpenTreeToggle(customer.id)}
                    >
                      <i
                        className={
                          customer.isOpenThee
                            ? "bx bx-caret-right text-success font-size-14"
                            : "bx bx-caret-down font-size-14"
                        }
                      />
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <h4 className="text-truncate mb-1">
                        {customer.name}: {customer.company}
                      </h4>
                    </div>
                  </div>
                  {customer.isOpenThee && (
                    <>
                      <ul className="list-unstyled" style={{ marginLeft: 100 }}>
                        {map(customer.subAccounts, (subAccount) => (
                          <li className="" key={subAccount.phone}>
                            <h6 className="font-size-15 fw-bold">
                              {subAccount.name}: {subAccount.phone}
                            </h6>
                            <Table className="table-nowrap">
                              <thead>
                                <tr>
                                  <th style={{ width: "70px" }}>No.</th>
                                  <th>Item</th>
                                  <th className="text-end">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {map(subAccount.listThing, (item, key) => (
                                  <tr key={key}>
                                    <td>{key}</td>
                                    <td>{item.item}</td>
                                    <td className="text-end">{item.price}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <td colSpan="2" className="text-end">
                                    Sub Total
                                  </td>
                                  <td className="text-end">
                                    {subAccount.total}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2" className="border-0 text-end">
                                    <strong>Shipping</strong>
                                  </td>
                                  <td className="border-0 text-end">$13.00</td>
                                </tr>
                                <tr>
                                  <td colSpan="2" className="border-0 text-end">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="border-0 text-end">
                                    <h4 className="m-0">{subAccount.total}</h4>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </li>
                        ))}
                      </ul>
                      <div className="text-end d-flex align-items-center justify-content-end border-top pt-2">
                        <h4 className="m-0">
                          <strong>Account Total</strong>
                        </h4>
                        <h4>{"_"}</h4>
                        <h4 className="m-0">{customer.accountTotal}</h4>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </PerfectScrollbar>
          </ul>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BillingSuperAdmin);
