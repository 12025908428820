import {
  UPDATE_STATE_FLOW,
  GET_FLOWS,
  GET_FLOWS_SUCCESS,
  CREATE_FLOW,
  CREATE_FLOW_SUCCESS,
  EDIT_FLOW,
  EDIT_FLOW_SUCCESS,
  GET_FLOW_BY_ID,
  GET_FLOW_BY_ID_SUCCESS,
  DELETE_FLOW,
  DELETE_FLOW_SUCCESS,
  API_ERROR_FLOWS,
  EDIT_FLOW_CONFIGURE,
} from "./actionTypes";

export const updateFlowState = (data) => ({
  type: UPDATE_STATE_FLOW,
  payload: data,
});

export const getFlowsAction = () => ({
  type: GET_FLOWS,
});

export const getFlowsSuccess = (data) => ({
  type: GET_FLOWS_SUCCESS,
  payload: data,
});

export const createFlowAction = (data, navigate) => ({
  type: CREATE_FLOW,
  payload: { data, navigate },
});

export const createFlowSuccess = (data) => ({
  type: CREATE_FLOW_SUCCESS,
  payload: data,
});

export const editFlowAction = (id, data, navigate) => ({
  type: EDIT_FLOW,
  payload: { id, data, navigate },
});

export const editFlowSuccess = (data) => ({
  type: EDIT_FLOW_SUCCESS,
  payload: data,
});

export const getFlowByIDAction = (data) => ({
  type: GET_FLOW_BY_ID,
  payload: data,
});

export const getFlowByIDSuccess = (data) => ({
  type: GET_FLOW_BY_ID_SUCCESS,
  payload: data,
});

export const deleteFlowAction = (data) => ({
  type: DELETE_FLOW,
  payload: data,
});

export const deleteFlowSuccess = (data) => ({
  type: DELETE_FLOW_SUCCESS,
  payload: data,
});

export const apiErrorFlows = (error) => {
  return {
    type: API_ERROR_FLOWS,
    payload: error,
  };
};
