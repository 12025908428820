import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal, ModalBody, Label, Input, FormFeedback } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

const ConfirmBlockModal = ({ show, onDeleteClick, onCloseClick, user }) => {
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      confirm_word: "",
      checkWORD: user.email || "",
    },
    validationSchema: Yup.object({
      confirm_word: Yup.string().matches(user.email),
    }),
    onSubmit: (values) => {
      console.log("onSubmit", values);

      validation.resetForm();

      onDeleteClick();
    },
  });

  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-danger bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-block-helper"></i>
            </div>
          </div>

          <p className="text-muted font-size-16 mb-4">
            Are you sure you want to block the Brand.
          </p>
          <div className="mb-3">
            <Label className="form-label">
              <span className="red_star">*</span> Please type brand email to
              confirm blocking "{user.email}"
            </Label>
            <Input
              name="confirm_word"
              className="form-control"
              placeholder="*"
              type="text"
              onChange={validation.handleChange}
              value={validation.values.confirm_word || ""}
              invalid={
                validation.touched.confirm_word &&
                validation.errors.confirm_word
                  ? true
                  : false
              }
            />
            {validation.touched.confirm_word &&
            validation.errors.confirm_word ? (
              <FormFeedback type="invalid">
                {validation.errors.confirm_word}
              </FormFeedback>
            ) : null}
          </div>
          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                validation.handleSubmit();
              }}
            >
              Block
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

ConfirmBlockModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default ConfirmBlockModal;
