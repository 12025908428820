/* USERS */
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const GET_CUSTOMERS_CONTACT = "GET_CUSTOMERS_CONTACT";
export const GET_CUSTOMERS_CONTACT_SUCCESS = "GET_CUSTOMERS_CONTACT_SUCCESS";
export const ADD_NEW_CUSTOMERS_CONTACT = "ADD_NEW_CUSTOMERS_CONTACT";
export const ADD_NEW_CUSTOMERS_CONTACT_SUCCESS =
  "ADD_NEW_CUSTOMERS_CONTACT_SUCCESS";
export const GET_CUSTOMERS_CONTACT_PROFILE = "GET_CUSTOMERS_CONTACT_PROFILE";
export const GET_CUSTOMERS_CONTACT_PROFILE_SUCCESS =
  "GET_CUSTOMERS_CONTACT_PROFILE_SUCCESS";
export const UPDATE_CUSTOMERS_CONTACT = "UPDATE_CUSTOMERS_CONTACT";
export const UPDATE_CUSTOMERS_CONTACT_SUCCESS =
  "UPDATE_CUSTOMERS_CONTACT_SUCCESS";
export const DELETE_CUSTOMERS_CONTACT = "DELETE_CUSTOMERS_CONTACT";
export const DELETE_CUSTOMERS_CONTACT_SUCCESS =
  "DELETE_CUSTOMERS_CONTACT_SUCCESS";
export const CLEAR_ON_LOGOUT = "CLEAR_ON_LOGOUT";

export const API_ERROR = "CONTACTS_USERS_API_ERROR";
