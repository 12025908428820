import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { size, map } from "lodash";

// const formateDate = (date, format) => {
//   const dateFormat = format ? format : "DD MMM Y";
//   const date1 = moment(new Date(date)).format(dateFormat);
//   return date1;
// };
// const toLowerCase1 = (str) => {
//   return str === "" || str === undefined ? "" : str.toLowerCase();
// };

const Name = ({ cell }) => {
  const values = cell.row.original;
  const fullNAme = `${values.first_name} ${values.last_name}`;
  return fullNAme || "";
};
const FirstName = ({ cell }) => {
  const values = cell.row.original;
  const fullNAme = `${values.first_name}`;
  return fullNAme || "";
};
const LastName = ({ cell }) => {
  const values = cell.row.original;
  const fullNAme = `${values.last_name}`;
  return fullNAme || "";
};

const Email = (cell) => {
  return cell.value ? cell.value : "";
};

const AssignedSR = (props) => {
  const isAssigned = props.srs.find(
    ({ id }) => props.row.original.user_id === id
  );

  if (isAssigned) {
    return isAssigned.first_name + " " + isAssigned.last_name;
  }

  return <p style={{ fontSize: 11, color: "red" }}>Not Assigned</p>;
};

const Tags = (cell) => {
  return (
    <>
      {map(
        cell.value,
        (tag, index) =>
          index < 2 && (
            <Link
              to="#"
              className="badge badge-soft-primary font-size-11 m-1"
              key={"_skill_" + cell.value + index}
            >
              {tag}
            </Link>
          )
      )}
      {size(cell.value) > 2 && (
        <Link
          to="#"
          className="badge badge-soft-primary font-size-11 m-1"
          key={"_skill_" + cell.value}
        >
          {size(cell.value) - 1} + more
        </Link>
      )}
    </>
  );
};

const Status = (cell) => {
  return <span className="font-size-10">{cell.value ? cell.value : ""}</span>;
};

const StatusBrand = (cell) => {
  return (
    <span className="font-size-10">
      {cell.value ? cell.value : "unregister"}
    </span>
  );
};

const Role = (cell) => {
  return (
    <span className="font-size-10">
      {cell.is_admin === 0 && "Sales Rep"}
      {cell.is_admin === 1 && "Admin"}
      {cell.is_admin === 2 && "Super Admin"}
    </span>
  );
};

const Phone = (cell) => {
  return cell.value ? cell.value : "";
};

const Projects = (cell) => {
  return cell.value ? cell.value : "";
};

const Address = (cell) => {
  return cell.value ? cell.value : "";
};

const Price = (cell) => {
  return cell.value ? "$" + parseFloat(cell.value) : "";
};

const Img = (cell) => {
  return (
    <>
      {!cell.value ? (
        <div className="avatar-xs">
          <span
            className="avatar-title rounded-circle"
            style={{ backgroundColor: "transparent" }}
          >
            {cell?.data[0]?.name?.charAt(0)}
          </span>
        </div>
      ) : (
        <div>
          <img className="rounded-circle avatar-xs" src={cell.value} alt="" />
        </div>
      )}
    </>
  );
};
const BusinessName = (cell) => {
  const name = cell.row.original?.a2p?.brand?.legal_business_name;
  return name;
};

export {
  Name,
  FirstName,
  LastName,
  Email,
  Tags,
  Projects,
  Img,
  Phone,
  Address,
  Role,
  Status,
  StatusBrand,
  BusinessName,
  AssignedSR,
  Price,
};
