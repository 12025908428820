import { UPDATE_STATE_CALL, CLEAR_CALL, ERROR } from "./actionTypes";

export const updateStateCall = (data) => ({
  type: UPDATE_STATE_CALL,
  payload: data,
});

export const clearCall = () => ({
  type: CLEAR_CALL,
  payload: {},
});

export const apiErrorCall = (error) => {
  return {
    type: ERROR,
    payload: error,
  };
};
