import {
  GET_INVOICES_SUCCESS,
  GET_INVOICES_USAGE_SUCCESS,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_BALANCE_SUCCESS,
  API_ERROR_INVOICE,
  CLEAR_INVOICES_ON_LOGOUT,
} from "./actionTypes";

const INIT_STATE = {
  invoices: [],
  invoicesUsage: [],
  invoiceDetail: {},
  balance: {},
  error: null,
};

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        error: null,
      };

    case GET_INVOICES_USAGE_SUCCESS:
      return {
        ...state,
        invoicesUsage: action.payload,
        error: null,
      };

    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
        error: null,
      };

    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload,
        error: null,
      };

    case CLEAR_INVOICES_ON_LOGOUT:
      return INIT_STATE;

    case API_ERROR_INVOICE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Invoices;
