import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { REQUEST_VOICE_MAILS, UPDATE_VOICE_MAILS_STATUS } from "./actionTypes";
import {
  apiErrorChat,
  fetchVoiceMailsSuccess,
  updateVoiceMailStatus_Success,
} from "./actions";

import { getUserRecordings, setUserRecordListened } from "helpers/API_hendlers";

function* requestVoiceMailsSaga({ payload: userId }) {
  try {
    const response = yield call(getUserRecordings, userId);
    yield put(fetchVoiceMailsSuccess(response.recordings));
  } catch (error) {
    yield put(apiErrorChat(error));
  }
}

function* setUserRecordListenedSage({ payload }) {
  try {
    const response = yield call(
      setUserRecordListened,
      payload.userId,
      payload.recordingSid
    );  

    yield put(
      updateVoiceMailStatus_Success(
        payload.recordingSid,
        response.recording_listened
      )
    );
  } catch (error) {
    yield put(apiErrorChat(error));
  }
}

function* chatSaga() {
  yield takeEvery(REQUEST_VOICE_MAILS, requestVoiceMailsSaga);
  yield takeEvery(UPDATE_VOICE_MAILS_STATUS, setUserRecordListenedSage);
}

export default chatSaga;
