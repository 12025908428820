import { takeEvery, put, call } from "redux-saga/effects";

// Chat Redux States
import { GET_MY_AI_DATA } from "./actionTypes";
import { getMyAIdataSuccess, apiErrorMyAI } from "./actions";

import { getChatToken } from "helpers/API_hendlers";

function* fetchMyAIdata() {
  try {
    // const response = yield call(getUsers);
    const testEl = [];
    for (let i = 0; i < 8; i++) {
      testEl.push(parseInt(Math.random() * 100));
    }

    yield put(getMyAIdataSuccess(testEl));
  } catch (error) {
    yield put(apiErrorMyAI(error));
  }
}

function* my_ai_saga() {
  yield takeEvery(GET_MY_AI_DATA, fetchMyAIdata);
}

export default my_ai_saga;
