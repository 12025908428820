import { UPDATE_STATE_CALL, CLEAR_CALL, ERROR } from "./actionTypes";

const INIT_STATE = {
  status: "",
  error: null,
  device: null,  
  callStatus: '',
  isShowModal: false,
};

const ChatReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATE_CALL:
      const data = action.payload;

      const nState = { ...state };

      if (data.hasOwnProperty("error")) {
        nState.error = data.error;
      }
      if (data.hasOwnProperty("status")) {
        nState.status = data.status;
      }
      if (data.hasOwnProperty("device")) {
        nState.device = data.device;
      }
      if (data.hasOwnProperty("callStatus")) {
        nState.callStatus = data.callStatus;
      }
      if (data.hasOwnProperty("isShowModal")) {
        nState.isShowModal = data.isShowModal;
      }

      return nState;

    case CLEAR_CALL:
      return INIT_STATE;

    default:
      return state;
  }
};

export default ChatReducer;
