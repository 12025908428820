import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { map } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Card, Row, Col, Spinner, Alert } from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";

import MessageItem from "../message_item";
// import { createTestVoiceMessage } from "helpers/API_hendlers";
// import { TEST_VOICE_MAIL } from "config";

import { createVcard } from "helpers/utility";
import { getSuggestion } from "helpers/API_hendlers";

export default function BlockMessages(props) {
  const {
    toggleCreateOrder,
    isMobMode,
    isMobModeChange,
    makeCall,
    customer,
    setModalConnect,
    orderLink,
  } = props;

  const [state, setState] = useState({
    media: null,
    text: "",
    attributes: {},
    selectedFile: null,
    loading: false,
  });
  const [errors, setError] = useState(null);

  const textRef = useRef();
  const _scrollRef = useRef();

  const { chat, user } = useSelector(
    (state) => ({
      chat: state.chat,
      user: state.Login.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (orderLink) {
      setState((ps) => ({ ...ps, text: ps.text + orderLink }));
    }
  }, [orderLink]);

  useEffect(() => {
    onCancelSend();
  }, [chat?.conversationProxy?.sid]);

  const isPinned =
    chat?.conversationProxy?._internalState?.attributes?.isPinned;

  useEffect(() => {
    if (chat.messages.length > 0) {
      scrollToBottom();
    }
  }, [chat.messages]);

  useEffect(() => {
    const textarea = textRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [state.text]);

  // const TEST = () => {
  //   const sender = chat.conversationProxy.uniqueName
  //     .replace(/\s/g, "")
  //     .replace(/[()-]/g, "");
  //   createTestVoiceMessage(TEST_VOICE_MAIL(sender, user.phone));
  // };

  const scrollToBottom = () => {
    if (_scrollRef?.current) {
      _scrollRef.current.scrollTop = _scrollRef.current.scrollHeight;
    }
  };

  const handlePinConversation = (convSID, isPinned) => {
    ClientConversation.updateConvAttribute(convSID, { isPinned: isPinned });
  };

  const handleInputMessText = (e) => {
    setState((pS) => ({
      ...pS,
      text: e.target.value,
    }));
  };

  const onCancelSend = () => {
    setState((pS) => ({
      ...pS,
      media: null,
      text: "",
      attributes: {},
      selectedFile: null,
      loading: false,
    }));
  };

  const handleAddMediaToMessage = async (e) => {
    e.preventDefault();
    try {
      const selectedFile = e.target.files[0];
      const fileSize = selectedFile.size;
      if (fileSize > 5000000) {
        setError("File size can't be more than 5MB");
        return;
      }
      const reader = new FileReader();
      if (selectedFile.type === "text/plain") {
        reader.readAsText(selectedFile);
      } else {
        reader.readAsArrayBuffer(selectedFile);
      }
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedFile.type });
        setState((ps) => ({ ...ps, media: blob, selectedFile }));
        setError(null);
      };
      reader.onerror = function () {
        console.log(reader.error);
      };
    } catch (error) {
      console.log(error);
    }
  };

  const addMessageTo = async () => {
    setState((pS) => ({
      ...pS,
      loading: true,
    }));
    setError(null);

    await ClientConversation.sendMessage(state)
      .then(() => {
        setState((pS) => ({
          ...pS,
          media: null,
          text: "",
          attributes: {},
          loading: false,
          selectedFile: null,
        }));
        setError(null);
      })
      .catch((e) => {
        console.log(e);
        setError("ERROR " + e?.message);
      });
  };

  const sendVcard = async (e) => {
    try {
      e.preventDefault();
      const { file_vcard, vcard_type, file_name } = await createVcard(
        user,
        user.image
      );
      setState((pS) => ({
        ...pS,
        media: file_vcard,
        selectedFile: {
          type: vcard_type,
          name: file_name,
        },
      }));
      setError(null);
    } catch (error) {
      console.log(error);
      setError("ERROR " + error?.message);
    }
  };

  const generateSuggestion = async (e) => {
    try {
      e.preventDefault();

      const contactId = customer?.id;
      const messages = chat.messages.map(message => {
        return {
          role: message.email === user.email ? "assistant" : "user",
          content: message.type == "media" ? message.filename : message.message,
        };
      });
      console.log("messages", JSON.stringify(messages));

      const { suggestion } = await getSuggestion({ contactId, messages });
      console.log("suggestion", suggestion);

      setState((pS) => ({
        ...pS,
        text: suggestion,
      }));
      setError(null);
    } catch (error) {
      console.log(error);
      setError("ERROR " + error?.message);
    }
  };

  return (
    <div className="w-100 user-chat">
      {!chat?.conversationProxy?.sid ? (
        <Card className="mb-1">
          <p className="text-center m-4">
            Create conversation first to start chatting
          </p>
        </Card>
      ) : (
        <Card className="mb-1">
          <div className="p-3 border-bottom ">
            <Row>
              <Col md="5" xs="9" className="d-flex flex-row align-items-center">
                {isMobMode.is && (
                  <div className="me-3" onClick={isMobModeChange}>
                    <i
                      style={{ fontSize: 30 }}
                      className="mdi mdi-keyboard-backspace me-1"
                    />
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <p className="font-size-15 m-0 me-2">
                    {chat.conversationProxy &&
                      chat.conversationProxy.friendlyName}
                    <br />
                    <span className="font-size-10 m-0 me-2">
                      {customer?.phone}
                    </span>
                  </p>
                  <i
                    id={`${chat.conversationProxy?.sid}_pin`}
                    className={`bx ${isPinned ? "bxs-pin" : "bx-pin"
                      } font-size-20 `}
                    onClick={() =>
                      handlePinConversation(
                        chat.conversationProxy?.sid,
                        !isPinned
                      )
                    }
                  />
                </div>
              </Col>
              <Col md="5" xs="3">
                <div className="d-flex align-items-center justify-content-end">
                  <p className="font-size-15 m-0">
                    Account:{" "}
                    <span
                      className={"handled"}
                      onClick={() => setModalConnect(true)}
                    >
                      {customer?.shopify_customer_id
                        ? "Connected"
                        : "Disconnected"}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <div className="chat-conversation p-3">
              <ul className="list-unstyled">
                <PerfectScrollbar
                  style={{
                    height: `calc(100vh - ${isMobMode.is ? "470px" : "400px"})`,
                  }}
                  containerRef={(ref) => (_scrollRef.current = ref)}
                >
                  {chat.messages.length > 0 &&
                    map(chat.messages, (message) => (
                      <MessageItem
                        key={message.id}
                        message={message}
                        user={user}
                      />
                    ))}
                </PerfectScrollbar>
              </ul>
            </div>
            <div className="p-3 chat-input-section position-relative">
              <Alert
                isOpen={errors}
                color="warning"
                role="alert"
                className="alert-dismissible fade show"
                toggle={() => {
                  setState((pS) => ({
                    ...pS,
                    showAlert: {
                      ...state.showAlert,
                      isShow: !state.showAlert.isShow,
                    },
                  }));
                }}
              >
                {errors}
              </Alert>
              <Row className="align-items-center">
                <Col>
                  <div className="position-relative">
                    <textarea
                      type="textarea"
                      ref={textRef}
                      rows={1}
                      style={{
                        overflow: "auto",
                        resize: "none",
                        maxHeight: "200px",
                      }}
                      // onKeyDown={handleKeyDown}
                      disabled={state.media && state.selectedFile}
                      value={state.text}
                      onChange={handleInputMessText}
                      className="form-control chat-input"
                      placeholder={
                        state.media && state.selectedFile
                          ? ""
                          : "Enter Message..."
                      }
                    />
                    {!state.text && (
                      <div className="chat-input-links">
                        <ul className="list-inline mb-0 d-flex align-items-center">
                          {state.media && state.selectedFile && (
                            <>
                              <li className="list-inline-item">
                                File: {state.selectedFile.name}
                              </li>

                              <li className="list-inline-item" title="Cancel">
                                <i
                                  className="mdi mdi-close-box me-1 text-danger font-size-20"
                                  onClick={onCancelSend}
                                />
                              </li>
                            </>
                          )}
                          {!state.media && (
                            <>
                              <li
                                className="list-inline-item"
                                title="Create Order"
                              >
                                <i
                                  className="bx bxs-spreadsheet me-1 pt-1 cp-2 font-size-26"
                                  onClick={() => toggleCreateOrder("sms")}
                                />
                              </li>

                              <li
                                className="list-inline-item"
                                title="Send your vCard"
                              >
                                <i
                                  className="mdi mdi-card-account-phone me-1 cp-2 font-size-22"
                                  onClick={sendVcard}
                                />
                              </li>

                              <li
                                className="list-inline-item position-relative"
                                title="Add Files (max size: 5MB)"
                              >
                                <label
                                  htmlFor="add_file"
                                  className="position-absolute top-0"
                                  id="Imagetooltip"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></label>
                                <i className="mdi mdi-file-plus me-1 cp-2 font-size-22" />
                                <input
                                  type="file"
                                  id="add_file"
                                  className="file-input"
                                  style={{ display: "none" }}
                                  onChange={handleAddMediaToMessage}
                                />
                              </li>
                              <li
                                className="list-inline-item position-relative"
                                title="Make a call"
                              >
                                <i
                                  className="mdi mdi-phone-outgoing-outline me-1 cp-2 font-size-22"
                                  onClick={() => makeCall(customer)}
                                />
                              </li>
                              <li
                                className="list-inline-item"
                                title="Get Suggestion"
                              >
                                <i
                                  className="mdi mdi-star-four-points me-1 cp-2 font-size-22"
                                  onClick={generateSuggestion}
                                />
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    type="button"
                    color="primary"
                    onClick={addMessageTo}
                    className="btn btn-primary btn-rounded chat-send w-md "
                  >
                    {state.loading ? (
                      <Spinner className="m-0 p-0" color="info" size={"sm"} />
                    ) : (
                      <>
                        <span className="d-none d-sm-inline-block me-2">
                          Send
                        </span>{" "}
                        <i className="mdi mdi-send" />
                      </>
                    )}
                  </Button>

                  {/* <Button
                  type="button"
                  color="primary"
                  onClick={TEST}
                  className="btn btn-primary btn-rounded chat-send w-md "
                >
                  <span className="d-none d-sm-inline-block me-2">create voicemail TEST</span>{" "}
                </Button> */}
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}
