import { CHANGE_LOADER } from "./actionTypes";

const INIT_STATE = {
  loader: false,
};

const LoaderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
};

export default LoaderReducer;
