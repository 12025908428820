import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_INVOICES,
  GET_INVOICES_USAGE,
  GET_INVOICE_DETAIL,
  GET_BALANCE,
} from "./actionTypes";
import {
  getInvoicesSuccess,
  getInvoicesUsageSuccess,
  getInvoiceDetailSuccess,
  getBalanceSuccess,
  apiErrorInvoice,
} from "./actions";

//Include Both Helper File with needed methods

import {
  getBillingList,
  getBillingUsage,
  getBillingById,
  getBillingBalance,
} from "helpers/API_hendlers";

function* fetchInvoices() {
  try {
    const response = yield call(getBillingList);
    yield put(getInvoicesSuccess(response.usageList));
  } catch (error) {
    yield put(apiErrorInvoice(error));
  }
}

function* fetchInvoicesUsage({ payload: { from, to } }) {
  try {
    const response = yield call(getBillingUsage, from, to);
    yield put(getInvoicesUsageSuccess(response.usage));
  } catch (error) {
    yield put(apiErrorInvoice(error));
  }
}

function* fetchInvoiceDetail({ invoiceId }) {
  try {
    const response = yield call(getBillingById, invoiceId);
    yield put(getInvoiceDetailSuccess(response));
  } catch (error) {
    yield put(apiErrorInvoice(error));
  }
}

function* fetchBalance() {
  try {
    const response = yield call(getBillingBalance);
    yield put(getBalanceSuccess(response.balance));
  } catch (error) {
    yield put(apiErrorInvoice(error));
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices);
  yield takeEvery(GET_INVOICES_USAGE, fetchInvoicesUsage);
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail);
  yield takeEvery(GET_BALANCE, fetchBalance);
}

export default invoiceSaga;
