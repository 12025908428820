import {
  UPDATE_STATE_CAMPAING,
  GET_CAMPAINGS,
  GET_CAMPAINGS_SUCCESS,
  CREATE_CAMPAING,
  CREATE_CAMPAING_SUCCESS,
  EDIT_CAMPAING,
  EDIT_CAMPAING_SUCCESS,
  GET_CAMPAING_BY_ID,
  GET_CAMPAING_BY_ID_SUCCESS,
  DELETE_CAMPAING,
  DELETE_CAMPAING_SUCCESS,
  API_ERROR_CAMPAINGS,  
} from "./actionTypes";

export const updateCampaingState = (data) => ({
  type: UPDATE_STATE_CAMPAING,
  payload: data,
});

export const getCampaingsAction = () => ({
  type: GET_CAMPAINGS,
});

export const getCampaingsSuccess = (data) => ({
  type: GET_CAMPAINGS_SUCCESS,
  payload: data,
});

export const createCampaingAction = (data, navigate) => ({
  type: CREATE_CAMPAING,
  payload: { data, navigate },
});

export const createCampaingSuccess = (data) => ({
  type: CREATE_CAMPAING_SUCCESS,
  payload: data,
});

export const editCampaingAction = (id, data, navigate) => ({
  type: EDIT_CAMPAING,
  payload: { id, data, navigate },
});

export const editCampaingSuccess = (data) => ({
  type: EDIT_CAMPAING_SUCCESS,
  payload: data,
});

export const getCampaingByIDAction = (data) => ({
  type: GET_CAMPAING_BY_ID,
  payload: data,
});

export const getCampaingByIDSuccess = (data) => ({
  type: GET_CAMPAING_BY_ID_SUCCESS,
  payload: data,
});

export const deleteCampaingAction = (data) => ({
  type: DELETE_CAMPAING,
  payload: data,
});

export const deleteCampaingSuccess = (data) => ({
  type: DELETE_CAMPAING_SUCCESS,
  payload: data,
});

export const apiErrorCampaings = (error) => {
  return {
    type: API_ERROR_CAMPAINGS,
    payload: error,
  };
};
