import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { loginSuccess } from "store/actions";

const Authmiddleware = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(
    (state) => ({
      token: state.Login?.auth?.token,
    }),
    shallowEqual
  );

  // console.log("get Authmiddleware", token, localStorage.getItem("authUser"));
  if (
    localStorage.getItem("authUser") === "null" ||
    !localStorage.getItem("authUser")
  ) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  if (!token && localStorage.getItem("authUser") !== "null") {
    const values = JSON.parse(localStorage.getItem("authUser"));
    dispatch(loginSuccess(values, navigate));
  }

  return <>{props.children}</>;
};

export default Authmiddleware;
