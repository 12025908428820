import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props) => {
  let breadcrumbItems = [props.title, props.breadcrumbItem];

  if (props.breadcrumbItems) {
    breadcrumbItems = props.breadcrumbItems.split("/");
  }  

  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{props.title}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbItems.map((crumb, index) => {
                let link = "";
                if (index === 0) {
                  link = "/";
                } else {
                  breadcrumbItems.forEach((cr, ind) => {
                    if (ind <= index) {
                      if (ind === 0) {
                        link += "/";
                      } else if (ind === index) {
                        link += cr;
                      } else {
                        link += cr + "/";
                      }
                    }
                  });
                }
                return (
                  <BreadcrumbItem key={crumb + index}>
                    <Link to={link}>{crumb}</Link>
                  </BreadcrumbItem>
                );
              })}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
