import React, { useEffect, useState } from "react";
import { Col, Container, Row, Label, Card, FormGroup } from "reactstrap";
import { map } from "lodash";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import moment from "moment";
import Flatpickr from "react-flatpickr";

import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";

import CardInvoice from "./card-invoice";
import { getInvoicesUsage as onGetInvoices, changeLoader } from "store/actions";

//

const initDate = new Date();

const InvoicesUsage = (props) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    // from: new Date(moment(initDate).startOf("month").format()),
    from: "",
    to: initDate,
  });

  const { invoices, loading } = useSelector((state) => ({
    invoices: state.invoices.invoicesUsage,
    loading: state.Loader.loader,
  }), shallowEqual);

  useEffect(() => {
    dispatch(changeLoader(true));
    const from = state.from ? moment(state.from).format("YYYY-MM-DD") : "";
    const to = state.to ? moment(state.to).format("YYYY-MM-DD") : "";
    dispatch(onGetInvoices(from, to));
  }, [dispatch, state.from, state.to]);

  return (
    <React.Fragment>
      <div className="page-content pageContent">
        <Container fluid>
          <Breadcrumbs
            title="Billings"
            breadcrumbItems={props?.router?.location?.pathname}
          />

          <Card className="p-3">
            <Row className="mb-0">
              <Col lg={6}>
                <FormGroup className="mb-0">
                  <Label className="form-label">From:</Label>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="mm/dd/yyyy"
                    value={state.from}
                    options={{
                      // minDate: new Date(),
                      dateFormat: "m-j-Y",
                    }}
                    onChange={(e) => {
                      const d = new Date(e);
                      setState({
                        ...state,
                        from: d,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup className="mb-0">
                  <Label className="form-label">To:</Label>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="mm/dd/yyyy"
                    value={state.to}
                    options={{
                      maxDate: new Date(),
                      dateFormat: "m-j-Y",
                    }}
                    onChange={(e) => {
                      const d = new Date(e);
                      setState({
                        ...state,
                        to: d,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Card>

          <Row>
            {map(invoices, (invoice, key) => (
              <CardInvoice data={invoice} key={"_invoice_" + key} />
            ))}
          </Row>
          {loading && (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    Load more
                  </Link>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoicesUsage.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
};

export default withRouter(InvoicesUsage);
