import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Label,
  UncontrolledAlert,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";

import { getInvoices as onGetInvoices } from "store/actions";

import TableContainer from "components/Common/TableContainer";
import { Price, Phone } from "pages/Contacts/ContactList/contactlistCol";

const InvoicesList = (props) => {
  const dispatch = useDispatch();

  const { invoices, error } = useSelector((state) => ({
    invoices: state.invoices.invoices,
    loading: state.Loader.loader,
    error: state.invoices.error,
  }));

  const [state, setState] = useState({ mode: 'Company Total', dropdownOpen: false });
  const toggle = () =>
    setState((ps) => ({ ...ps, dropdownOpen: !ps.dropdownOpen }));
  const handleChange = (e) => {
    setState((ps) => ({ ...ps, mode: e.target.name }));
  };

  useEffect(() => {
    dispatch(onGetInvoices());
  }, [dispatch]);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: "From",
        accessor: "from",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "To",
        accessor: "to",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        },
      },
      {
        Header: "Amount",
        accessor: "price",
        filterable: true,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/invoices-detail/${cellProps.row.original.id}`}
                className="text-primary"
                onClick={() => {}}
              >
                <i className="mdi mdi-send font-size-18" id="go_to_chat" />
                <UncontrolledTooltip placement="top" target="go_to_chat">
                  Go to details
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ];

    return cols;
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Billings"
            breadcrumbItems={props?.router?.location?.pathname}
          />
          {!!error && (
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-block-helper me-2"></i>
              {error?.msg}
            </UncontrolledAlert>
          )}

          <Card>
            <CardBody>
              <Label className="form-label">List Mode:</Label>
              <div className="d-flex">
                <Dropdown
                  isOpen={state.dropdownOpen}
                  toggle={toggle}
                  direction={"down"}
                >
                  <DropdownToggle caret={true}>
                    {state.mode || "Dropdown"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      name="Company Total"
                      active={state.mode === "Company Total"}
                      onClick={handleChange}
                    >
                      Company Total
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      name="By Sales Rep"
                      active={state.mode === "By Sales Rep"}
                      onClick={handleChange}
                    >
                      By Sales Rep
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </CardBody>
          </Card>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {" "}
                  <TableContainer
                    columns={columns}
                    data={invoices}
                    isGlobalFilter={true}
                    isAddCustList={false}
                    customPageSize={10}
                    className="custom-header-css"
                  />{" "}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
};

export default withRouter(InvoicesList);
