import { call, put, takeEvery } from "redux-saga/effects";

import {
  CONFIRM_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  MODIFY_CURRENT_USER,
  REGISTER_USER,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  registerUserSuccessful,
  confirmCreatePasswordSuccessful,
  logoutUserSuccess,
  modifyCurrentUserSuccess,
} from "./actions";

import {
  changeLoader,
  clearChat,
  clearContactsOnLogout,
  clearInvoicesOnLogout,
} from "store/actions";

import {
  register,
  login,
  modifyCurrentUser,
  confirmCreatePassword_API,
} from "helpers/API_hendlers";

function* confirmCreatePassword({ payload: { data, navigate } }) {
  try {
    const response = yield call(confirmCreatePassword_API, {
      ...data,
    });

    // console.log("Trying confirmCreatePassword user", response);
    yield put(confirmCreatePasswordSuccessful(response));

    navigate("/login");
  } catch (error) {
    console.log("There was an error confirmCreatePassword: ", error);
    yield put(apiError(error));
  }
}

function* registerUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(register, user);

    // console.log("Trying to register user", response);

    yield put(registerUserSuccessful(response));
    navigate("/auth-email-verification", {
      state: { ...user },
      replace: true,
    });
  } catch (error) {
    console.log("There was an error registering: ", error);
    yield put(apiError(error));
  }
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, {
      email: user.email,
      password: user.password,
    });

    // console.log("LOGIN response", response);

    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));

    if (response.user?.is_admin === 0) {
      history("/chat");
    }
    if (response.user?.is_admin === 1) {
      if (response.user?.m_service_sid) {
        history("/sales-reps");
      } else {
        history("/onboarding-flow");
      }
    }
    if (response.user?.is_admin === 2) {
      history("/dashboard");
    }
    ClientConversation.ChatToken();
    ClientVoice._getVoiceToken();
  } catch (error) {
    yield put(apiError(error));
  }
}

function* modifyCurrentUserSaga(payload) {
  try {
    // console.log("payload", payload);

    const response = yield call(modifyCurrentUser, payload.payload);
    // console.log("response", response);

    const currentUser = JSON.parse(localStorage.getItem("authUser"));
    currentUser.user = response.user;
    localStorage.setItem("authUser", JSON.stringify(currentUser));
    yield put(modifyCurrentUserSuccess(response.user));
    yield put(changeLoader(false));
  } catch (error) {
    yield put(apiError(error));
    yield put(changeLoader(false));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    yield put(clearChat());
    yield put(logoutUserSuccess());
    yield put(clearContactsOnLogout());
    yield put(clearInvoicesOnLogout());

    ClientConversation.close();
    const device = ClientVoice.getDevice();
    device.destroy();

    history("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(CONFIRM_PASSWORD, confirmCreatePassword);
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(MODIFY_CURRENT_USER, modifyCurrentUserSaga);
}

export default authSaga;
